
import bg from '../src/assece/coming-soon.png';
function App() {
  return (
    <div className="bg-gray-100">
      <div className='flex justify-center  '>
          <img className='w-[100%] h-[100%]  mt-20  md:w-[40%] md:h-[40%]' src={bg} alt=" "/>
      </div>
      <div>
          <h1 className='text-center text-[38px] font-bold text-gray-500 '>coming soon... </h1>
          <hr className='w-[65%] h-1/2 bg-gray-900 md:w-[30%] flex justify-center items-center mx-auto'/>
          <h5 className='text-center text-[24px] font-bold text-gray-600'>  Billion </h5>
        
      </div>
    </div>
  );
}

export default App;
